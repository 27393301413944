
body {
    background-color: #AAA;
    display: block;
    flex-direction: column;
    justify-content: right;
    align-items: center;
    margin:  0;
    height: 100vh;

    --row-count: 10;
    --collumn-count:10;
    --cell-size: 7vmin;
    
}
button {
    /* background: none; */
    background: #4a425a;
    border: 0.25vmin solid #060000;
    padding: 0.75vmin 1vmin;
    border-radius: .5vmin;
    font-weight: bold;
    /* color: #201241; */
    color: goldenrod;
    cursor: pointer;
    font-size: 2.5vmin;
    display: flex;
    margin: auto;
    z-index: 2;
}
.header {
    padding: 2vmin;
    text-align: center;
    /* background: #1abc9c; */
    background:  -webkit-linear-gradient(#201241, #38495a);
    /* #201241; */
    color: gold;
    font-size: 3vmin;
    width: 100vw;
    z-index: 2;
    
}
.settings {
    display: flex;
    flex-direction: row;
}
.game {
 display: flex;
 flex-direction: column;
 z-index: 2;

}

.content {
    display: flex;
    margin: auto;
}

.score-board {
    flex: 1 1 30vmin;
    /* display: flex */
}


.board {
    flex: 1 1 70vmin;
}
.dungeon {
    display: grid;
    grid-template-columns: repeat(var(--collumn-count),var(--cell-size) );
    grid-template-rows: repeat(var(--row-count), var(--cell-size));
    /* width: 560px;
    height: 560px; */
    /* display: flex;
    flex-wrap: wrap; */
    padding-top: 10px;
    margin: auto;
    
    
}

.dungeon-difficult {
    --row-count: 8;
    --collumn-count: 8;

}
.dungeon-easy {
    --row-count: 20;
    --collumn-count: 20;
    --cell-size:4vmin;
    
}
.dungeon-normal {
    --row-count: 10;
    --collumn-count:10;
}
.dungeon img {

    width: var(--cell-size);
    height: var(--cell-size);
    box-sizing: border-box;
    /* border: 2px solid gray; */
    border-radius: .5vmin;
    z-index: 2;
}
.dungeon.img.dragon {
    background-color: red;

}
.shine {
    

    animation: flipNFlash 1s ease forwards; 
}

@keyframes flash {
    0% {
        background-color: none;
    }
   
    50% {
        background-color: gold;
    }
    
    100% {
        background-color: none;
    }
    
}
@keyframes flipNFlash {
    0% {
      transform: rotateX(0) rotatey(0) r;
      background: none;
      border-color: #333;
      filter: opacity(95%);
    }
    45% {
      transform: rotateX(90deg) rotateY(-90deg);
      background: none;
      border-color: #333;
      filter: opacity(50%);
    }
    55% {
      transform: rotateX(90deg) rotateY(-90deg);
      background: gold;
      border-color: none;
      filter: blur(5px) opacity(50%);

    }
    100% {
      transform: rotateX(0deg) rotateY(0deg);
      background: gold;
      border-color: none;
      filter: blur(5px) opacity(50%);
    }
}

@media only screen and (max-width:810px) {
    .content {
        display: flex;
        flex-direction: column;
        margin: auto;
    }
    
    .score-board {
        flex: 1 1 30vw;
        /* display: flex */
    }
    
    
    .board {
        flex: 1 1 70vw;
    }
}
/* @media only screen and (max-width:420px) {
    .dungeon img {
        width: 35px;
        height: 35px;

    }
.dungeon {
    
    width: 280px;
    height: 280px;
    display: flex;
    flex-wrap: wrap;
    padding-top: 5px;
    margin: auto;
    
}
button {
    background: none;
    border: 2px solid #060000;
    padding: 3px 6px;
    border-radius: 4px;
    font-weight: bold;
    color: #201241;
    cursor: pointer;
    font-size: 1.5em;
    display: flex;
    margin: auto;
}

    
}
 */